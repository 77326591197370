// Basic.js

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { Button } from "@mui/material";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import logoImage from "assets/images/HMS.webp";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <BasicLayout>
      <Card>
        <MDBox>
          <MDTypography
            variant="h4"
            fontWeight="medium"
            color="white"
            mt={1}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <img src={logoImage} alt="veera" style={{ width: "150px", height: "140px" }} />
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2} sx={{ borderRadius: "8px", backgroundColor: "#FFFFFF" }}>
              <MDInput
                type="username"
                label="Username"
                fullWidth
                sx={{
                  borderRadius: "28px",
                  backgroundColor: "#E4E7E7",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "28px",
                    height: "42px",
                  },
                  "& .MuiInputBase-input": {
                    padding: "8px 12px",
                    height: "20px",
                    borderRadius: "28px",
                  },
                  "& fieldset": {
                    borderRadius: "28px",
                  },
                }}
              />
            </MDBox>
            <MDBox mb={2} sx={{ borderRadius: "8px", backgroundColor: "#FFFFFF" }}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                sx={{
                  borderRadius: "28px",
                  backgroundColor: "#E4E7E7",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "28px",
                    height: "42px",
                  },
                  "& .MuiInputBase-input": {
                    padding: "8px 12px",
                    height: "20px",
                    borderRadius: "28px",
                  },
                  "& fieldset": {
                    borderRadius: "28px",
                  },
                }}
              />
            </MDBox>
            <MDBox mt={5} mb={1}>
              <Button
                variant="gradient"
                sx={{
                  background: "linear-gradient(45deg, #de157a, #712c8c)",
                  borderRadius: "28px",
                  color: "#ffffff",
                  width: "100%",
                  padding: "8px 10px",
                  fontSize: "1rem",
                  "&:hover": {
                    background: "linear-gradient(45deg, #de157a, #712c8c)",
                  },
                  "&:active": {
                    background: "linear-gradient(45deg, #de157a, #712c8c)",
                  },
                }}
              >
                LOG IN
              </Button>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
