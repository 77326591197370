import React, { useState } from 'react';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export const initialDoctors = [
  { id: 1, name: 'Dr. John Doe', specialty: 'Cardiology', degree: 'MD', type: 'Full-time', label: 'Dr. John Doe - Cardiology', availableDays: [], timing: '' },
  { id: 2, name: 'Dr. Jane Smith', specialty: 'Neurology', degree: 'PhD', type: 'Visiting', label: 'Dr. Jane Smith - Neurology', availableDays: ['Monday', 'Wednesday'], timing: '9am to 11am' },
  { id: 3, name: 'Dr. Albert Johnson', specialty: 'Orthopedics', degree: 'DO', type: 'Full-time', label: 'Dr. Albert Johnson - Orthopedics', availableDays: [], timing: '' },
];

const Doctors = () => {
  const [doctors, setDoctors] = useState(initialDoctors);
  const [doctorName, setDoctorName] = useState('');
  const [specialty, setSpecialty] = useState('');
  const [degree, setDegree] = useState('');
  const [type, setType] = useState('Full-time');
  const [availableDays, setAvailableDays] = useState([]);
  const [timing, setTiming] = useState('');
  const [nextId, setNextId] = useState(doctors.length + 1); // Counter for generating IDs

  // State for editing modal
  const [editingDoctorId, setEditingDoctorId] = useState(null);
  const [editedDoctorName, setEditedDoctorName] = useState('');
  const [editedSpecialty, setEditedSpecialty] = useState('');
  const [editedDegree, setEditedDegree] = useState('');
  const [editedType, setEditedType] = useState('Full-time');
  const [editedAvailableDays, setEditedAvailableDays] = useState([]);
  const [editedTiming, setEditedTiming] = useState('');

  // Modal state
  const [openModal, setOpenModal] = useState(false);

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (doctorName.trim() === '' || specialty.trim() === '' || degree.trim() === '') return;

    // Create new doctor object
    const newDoctor = {
      id: nextId,
      name: `Dr. ${doctorName}`,
      specialty: specialty,
      degree: degree,
      type: type,
      availableDays: type === 'Visiting' ? availableDays : [],
      timing: type === 'Visiting' ? timing : '',
      label: `Dr. ${doctorName} - ${specialty}`,
    };

    // Add new doctor to the doctors list
    setDoctors([...doctors, newDoctor]);

    // Increment nextId for the next doctor
    setNextId(nextId + 1);

    // Clear inputs
    setDoctorName('');
    setSpecialty('');
    setDegree('');
    setType('Full-time');
    setAvailableDays([]);
    setTiming('');
  };

  // Handle input changes
  const handleDoctorNameChange = (e) => {
    setDoctorName(e.target.value);
  };

  const handleSpecialtyChange = (e) => {
    setSpecialty(e.target.value);
  };

  const handleDegreeChange = (e) => {
    setDegree(e.target.value);
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  const handleAvailableDaysChange = (e) => {
    const value = e.target.value;
    setAvailableDays(value);
  };

  const handleTimingChange = (e) => {
    setTiming(e.target.value);
  };

  // Open modal for editing doctor
  const handleEditDoctor = (doctorId) => {
    const doctorToEdit = doctors.find((doctor) => doctor.id === doctorId);
    if (doctorToEdit) {
      setEditingDoctorId(doctorId);
      setEditedDoctorName(doctorToEdit.name);
      setEditedSpecialty(doctorToEdit.specialty);
      setEditedDegree(doctorToEdit.degree);
      setEditedType(doctorToEdit.type);
      setEditedAvailableDays(doctorToEdit.availableDays);
      setEditedTiming(doctorToEdit.timing);
      setOpenModal(true);
    }
  };

  // Handle saving edited doctor
  const handleSaveEditedDoctor = () => {
    const updatedDoctors = doctors.map((doctor) =>
      doctor.id === editingDoctorId
        ? {
            ...doctor,
            name: editedDoctorName,
            specialty: editedSpecialty,
            degree: editedDegree,
            type: editedType,
            availableDays: editedType === 'Visiting' ? editedAvailableDays : [],
            timing: editedType === 'Visiting' ? editedTiming : '',
          }
        : doctor
    );
    setDoctors(updatedDoctors);
    setEditingDoctorId(null);
    setEditedDoctorName('');
    setEditedSpecialty('');
    setEditedDegree('');
    setEditedType('Full-time');
    setEditedAvailableDays([]);
    setEditedTiming('');
    setOpenModal(false);
  };

  // Close modal
  const handleCloseModal = () => {
    setEditingDoctorId(null);
    setEditedDoctorName('');
    setEditedSpecialty('');
    setEditedDegree('');
    setEditedType('Full-time');
    setEditedAvailableDays([]);
    setEditedTiming('');
    setOpenModal(false);
  };

  const columns = [
    { Header: '#', accessor: 'serialNo', width: '5%', align: 'center' },
    { Header: 'Name', accessor: 'name', width: '20%', align: 'left' },
    { Header: 'Specialty', accessor: 'specialty', width: '20%', align: 'center' },
    { Header: 'Degree', accessor: 'degree', width: '10%', align: 'center' },
    { Header: 'Type', accessor: 'type', width: '10%', align: 'center' },
    { Header: 'Available Days', accessor: 'availableDays', width: '20%', align: 'center' },
    { Header: 'Timing', accessor: 'timing', width: '15%', align: 'center' },
    { Header: 'Action', accessor: 'action', align: 'center' },
  ];

  // Prepare rows for DataTable
  const rows = doctors.map((doctor, index) => ({
    serialNo: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {index + 1}
      </MDTypography>
    ),
    name: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {doctor.name}
      </MDTypography>
    ),
    specialty: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {doctor.specialty}
      </MDTypography>
    ),
    degree: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {doctor.degree}
      </MDTypography>
    ),
    type: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {doctor.type}
      </MDTypography>
    ),
    availableDays: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium" sx={{ whiteSpace: 'pre-line' }}>
        {doctor.type === 'Visiting' ? doctor.availableDays.join(', ') : '-'}
      </MDTypography>
    ),
    timing: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {doctor.type === 'Visiting' ? doctor.timing : '-'}
      </MDTypography>
    ),
    action: (
      <MDTypography
        component="button"
        variant="caption"
        color="text"
        fontWeight="medium"
        onClick={() => handleEditDoctor(doctor.id)}
      >
        Edit
      </MDTypography>
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="flex flex-col justify-center items-center mt-8 mx-5">
        <div className="w-4/5 flex  justify-center">
          <div className="w-4/5 p-10 mx-10 my-5 bg-white rounded-lg shadow-md">
            <h2 className="text-lg font-medium text-gray-800 mb-4">Add Doctor</h2>

            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <MDInput label="Doctor Name" value={doctorName} onChange={handleDoctorNameChange} fullWidth />
              </div>

              <div className="mb-4">
                <MDInput label="Specialty" value={specialty} onChange={handleSpecialtyChange} fullWidth />
              </div>

              <div className="mb-4">
                <MDInput label="Degree" value={degree} onChange={handleDegreeChange} fullWidth />
              </div>

              <div className="mb-4">
                <RadioGroup row value={type} onChange={handleTypeChange}>
                  <FormControlLabel value="Full-time" control={<Radio />} label="Full-time" />
                  <FormControlLabel value="Visiting" control={<Radio />} label="Visiting" />
                </RadioGroup>
              </div>

              {type === 'Visiting' && (
                <>
                  <div className="mb-4">
                    <Select
                      multiple
                      value={availableDays}
                      onChange={handleAvailableDaysChange}
                      displayEmpty
                      fullWidth
                      className="p-3"
                      renderValue={(selected) => (selected.length === 0 ? 'Available Days' : selected.join(', '))}
                    >
                      {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                        <MenuItem key={day} value={day}>
                          <Checkbox checked={availableDays.indexOf(day) > -1} />
                          {day}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>

                  <div className="mb-4">
                    <MDInput label="Timing" value={timing} onChange={handleTimingChange} fullWidth />
                  </div>
                </>
              )}

              <div className="mt-6 text-center">
                <MDButton variant="gradient" color="info" type="submit">
                  Add Doctor
                </MDButton>
              </div>
            </form>
          </div>
        </div>
        <div className="w-full">
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="customGradient"
                    borderRadius="lg"
                    coloredShadow="#EA257C"
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  >
                    <MDTypography variant="h6" color="white">
                      Available Doctors
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </div>
      </div>

      {/* Editing Modal */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="edit-doctor-modal"
        aria-describedby="modal to edit doctor"
      >
        <div className="modal-container flex justify-center mt-20 ">
          <Card className="modal-card w-2/5  ">
            <MDBox p={3}>
              <h2 className="text-lg font-medium text-gray-800 mb-5 mx-5">Edit Doctor</h2>
              <div className="mb-5 mx-5">
                <MDInput
                  label="Doctor Name"
                  value={editedDoctorName}
                  onChange={(e) => setEditedDoctorName(e.target.value)}
                  fullWidth
                />
              </div>

              <div className="mb-5 mx-5">
                <MDInput
                  label="Specialty"
                  value={editedSpecialty}
                  onChange={(e) => setEditedSpecialty(e.target.value)}
                  fullWidth
                />
              </div>

              <div className="mb-5 mx-5">
                <MDInput
                  label="Degree"
                  value={editedDegree}
                  onChange={(e) => setEditedDegree(e.target.value)}
                  fullWidth
                />
              </div>

              <div className="mb-5 mx-5">
                <RadioGroup row value={editedType} onChange={(e) => setEditedType(e.target.value)}>
                  <FormControlLabel value="Full-time" control={<Radio />} label="Full-time" />
                  <FormControlLabel value="Visiting" control={<Radio />} label="Visiting" />
                </RadioGroup>
              </div>

              {editedType === 'Visiting' && (
                <>
                  <div className="mb-5 mx-5">
                    <Select
                      multiple
                      value={editedAvailableDays}
                      onChange={(e) => setEditedAvailableDays(e.target.value)}
                      displayEmpty
                      fullWidth
                      className="p-3"
                      renderValue={(selected) => (selected.length === 0 ? 'Available Days' : selected.join(', '))}
                    >
                      {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                        <MenuItem key={day} value={day}>
                          <Checkbox checked={editedAvailableDays.indexOf(day) > -1} />
                          {day}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>

                  <div className="mb-5 mx-5">
                    <MDInput
                      label="Timing"
                      value={editedTiming}
                      onChange={(e) => setEditedTiming(e.target.value)}
                      fullWidth
                    />
                  </div>
                </>
              )}

              <div className="flex justify-end gap-5 mx-5">
                <MDButton variant="outlined" color="primary" onClick={handleCloseModal}>
                  Cancel
                </MDButton>
                <MDButton variant="gradient" color="info" onClick={handleSaveEditedDoctor}>
                  Save
                </MDButton>
              </div>
            </MDBox>
          </Card>
        </div>
      </Modal>
    </DashboardLayout>
  );
};

export default Doctors;
