import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import Modal from '@mui/material/Modal';
import { InitialServices } from 'layouts/Services/Services';


const InPatientsLists = () => {
  const initialInPatients = [
    {
      id: 1,
      name: 'Alice Johnson',
      age: 40,
      uhid: 'UHID001',
      admissionDate: '2024-01-15',
      dischargeDate: '2024-01-20',
      services: 'Cardiology',
      fees: 500,
      pressure: '120/80',
      spo2: '98%',
    },
    {
      id: 2,
      name: 'Bob Williams',
      age: 50,
      uhid: 'UHID002',
      admissionDate: '2024-02-10',
      dischargeDate: '2024-02-18',
      services: 'Neurology',
      fees: 700,
      pressure: '130/85',
      spo2: '97%',
    },
    {
      id: 3,
      name: 'Catherine Davis',
      age: 35,
      uhid: 'UHID003',
      admissionDate: '2024-03-05',
      dischargeDate: '2024-03-12',
      services: 'Orthopedics',
      fees: 600,
      pressure: '110/75',
      spo2: '99%',
    },
    {
      id: 4,
      name: 'Daniel Martinez',
      age: 45,
      uhid: 'UHID004',
      admissionDate: '2024-04-01',
      dischargeDate: '2024-04-10',
      services: 'Gastroenterology',
      fees: 550,
      pressure: '125/80',
      spo2: '96%',
    },
    {
      id: 5,
      name: 'Eva Taylor',
      age: 60,
      uhid: 'UHID005',
      admissionDate: '2024-05-15',
      dischargeDate: '2024-05-22',
      services: 'Dermatology',
      fees: 500,
      pressure: '135/90',
      spo2: '95%',
    },
  ];

  const [inPatients, setInPatients] = useState(initialInPatients);
  const [editingInPatientId, setEditingInPatientId] = useState(null);
  const [viewingInPatient, setViewingInPatient] = useState(null);
  const [editedInPatientUHID, setEditedInPatientUHID] = useState('');
  const [editedInPatientName, setEditedInPatientName] = useState('');
  const [editedInPatientAge, setEditedInPatientAge] = useState('');
  const [editedInPatientAdmissionDate, setEditedInPatientAdmissionDate] = useState('');
  const [editedInPatientDischargeDate, setEditedInPatientDischargeDate] = useState('');
  const [editedInPatientServices, setEditedInPatientServices] = useState('');
  const [editedInPatientFees, setEditedInPatientFees] = useState('');
  const [editedInPatientPressure, setEditedInPatientPressure] = useState('');
  const [editedInPatientSPO2, setEditedInPatientSPO2] = useState('');

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);

  const handleEditInPatient = (inPatientId) => {
    const inPatientToEdit = inPatients.find((inPatient) => inPatient.id === inPatientId);
    if (inPatientToEdit) {
      setEditingInPatientId(inPatientId);
      setEditedInPatientUHID(inPatientToEdit.uhid);
      setEditedInPatientName(inPatientToEdit.name);
      setEditedInPatientAge(inPatientToEdit.age);
      setEditedInPatientAdmissionDate(inPatientToEdit.admissionDate);
      setEditedInPatientDischargeDate(inPatientToEdit.dischargeDate);
      setEditedInPatientServices(inPatientToEdit.services);
      setEditedInPatientFees(inPatientToEdit.fees);
      setEditedInPatientPressure(inPatientToEdit.pressure);
      setEditedInPatientSPO2(inPatientToEdit.spo2);
      setOpenEditModal(true);
    }
  };

  const handleSaveEditedInPatient = () => {
    const updatedInPatients = inPatients.map((inPatient) =>
      inPatient.id === editingInPatientId
        ? {
          ...inPatient,
          uhid: editedInPatientUHID,
          name: editedInPatientName,
          age: parseInt(editedInPatientAge, 10),
          admissionDate: editedInPatientAdmissionDate,
          dischargeDate: editedInPatientDischargeDate,
          services: editedInPatientServices,
          fees: parseFloat(editedInPatientFees),
          pressure: editedInPatientPressure,
          spo2: editedInPatientSPO2,
        }
        : inPatient
    );
    setInPatients(updatedInPatients);
    setEditingInPatientId(null);
    setEditedInPatientUHID('');
    setEditedInPatientName('');
    setEditedInPatientAge('');
    setEditedInPatientAdmissionDate('');
    setEditedInPatientDischargeDate('');
    setEditedInPatientServices('');
    setEditedInPatientFees('');
    setEditedInPatientPressure('');
    setEditedInPatientSPO2('');
    setOpenEditModal(false);
  };

  const handleCloseEditModal = () => {
    setEditingInPatientId(null);
    setEditedInPatientUHID('');
    setEditedInPatientName('');
    setEditedInPatientAge('');
    setEditedInPatientAdmissionDate('');
    setEditedInPatientDischargeDate('');
    setEditedInPatientServices('');
    setEditedInPatientFees('');
    setEditedInPatientPressure('');
    setEditedInPatientSPO2('');
    setOpenEditModal(false);
  };

  const handleViewInPatient = (inPatientId) => {
    const inPatientToView = inPatients.find((inPatient) => inPatient.id === inPatientId);
    if (inPatientToView) {
      setViewingInPatient(inPatientToView);
      setOpenViewModal(true);
    }
  };

  const handleCloseViewModal = () => {
    setViewingInPatient(null);
    setOpenViewModal(false);
  };

  const handleEditButtonClickInViewModal = () => {
    if (viewingInPatient) {
      handleEditInPatient(viewingInPatient.id);
      setOpenViewModal(false);
    }
  };

  const [searchTerm, setSearchTerm] = useState('');

  const filteredInPatients = inPatients.filter((inPatient) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      inPatient.uhid.toLowerCase().includes(searchLower) ||
      inPatient.name.toLowerCase().includes(searchLower) ||
      inPatient.admissionDate.toLowerCase().includes(searchLower) ||
      inPatient.dischargeDate.toLowerCase().includes(searchLower) ||
      inPatient.age.toString().includes(searchLower)
    );
  });

  const columns = [
    { Header: '#', accessor: 'serialNo', width: '5%', align: 'center' },
    { Header: 'UHID', accessor: 'uhid', width: '15%', align: 'center' },
    { Header: 'Name', accessor: 'name', width: '20%', align: 'left' },
    { Header: 'Age', accessor: 'age', width: '10%', align: 'center' },
    { Header: 'Admission Date', accessor: 'admissionDate', width: '15%', align: 'center' },
    { Header: 'Discharge Date', accessor: 'dischargeDate', width: '15%', align: 'center' },
    { Header: 'Action', accessor: 'action', align: 'center' },
  ];

  const rows = filteredInPatients.map((inPatient, index) => ({
    serialNo: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {index + 1}
      </MDTypography>
    ),
    uhid: (
      <MDTypography
        component="button"
        variant="caption"
        color="text"
        fontWeight="medium"
        onClick={() => handleViewInPatient(inPatient.id)}
      >
        {inPatient.uhid}
      </MDTypography>
    ),
    name: (
      <MDTypography
        component="button"
        variant="caption"
        color="text"
        fontWeight="medium"
        onClick={() => handleViewInPatient(inPatient.id)}
      >
        {inPatient.name}
      </MDTypography>
    ),
    age: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {inPatient.age}
      </MDTypography>
    ),
    admissionDate: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {inPatient.admissionDate}
      </MDTypography>
    ),
    dischargeDate: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {inPatient.dischargeDate}
      </MDTypography>
    ),
    action: (
      <MDButton variant="text" color="info" size="small" onClick={() => handleEditInPatient(inPatient.id)}>
        Edit
      </MDButton>
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={4}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={3}
                mt={-3}
                py={2}
                px={2}
                variant="gradient"
                bgColor="customGradient"
                borderRadius="lg"
                coloredShadow="#EA257C"
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <MDTypography variant="h6" color="white">
                  In Patients
                </MDTypography>
                <MDBox display="flex" gap={2}>
                  <div >
                    <MDInput
                      label="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    /></div>
                  <Link to="/patientsregistration">
                    <MDButton variant="contained" color="white" >
                      Add IP
                    </MDButton>
                  </Link>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* Edit Modal */}
      <Modal open={openEditModal} onClose={handleCloseEditModal}>
        <Card
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            p: 4,
          }}
        >
          <MDTypography variant="h5" mb={2} fontWeight="bold">
            Edit In-Patient Details
          </MDTypography>
          <MDBox mb={2}>
            <MDInput
              label="UHID"
              type="text"
              fullWidth
              value={editedInPatientUHID}
              onChange={(e) => setEditedInPatientUHID(e.target.value)}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              label="Name"
              type="text"
              fullWidth
              value={editedInPatientName}
              onChange={(e) => setEditedInPatientName(e.target.value)}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              label="Age"
              type="number"
              fullWidth
              value={editedInPatientAge}
              onChange={(e) => setEditedInPatientAge(e.target.value)}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              label="Admission Date"
              type="date"
              fullWidth
              value={editedInPatientAdmissionDate}
              onChange={(e) => setEditedInPatientAdmissionDate(e.target.value)}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              label="Discharge Date"
              type="date"
              fullWidth
              value={editedInPatientDischargeDate}
              onChange={(e) => setEditedInPatientDischargeDate(e.target.value)}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              label="Services"
              type="text"
              fullWidth
              value={editedInPatientServices}
              onChange={(e) => setEditedInPatientServices(e.target.value)}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              label="Fees"
              type="number"
              fullWidth
              value={editedInPatientFees}
              onChange={(e) => setEditedInPatientFees(e.target.value)}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              label="Blood Pressure"
              type="text"
              fullWidth
              value={editedInPatientPressure}
              onChange={(e) => setEditedInPatientPressure(e.target.value)}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              label="SpO2"
              type="text"
              fullWidth
              value={editedInPatientSPO2}
              onChange={(e) => setEditedInPatientSPO2(e.target.value)}
            />
          </MDBox>
          <MDBox display="flex" justifyContent="flex-end" >
          <MDButton variant="contained" color="error" onClick={handleCloseEditModal} sx={{ mr: 2 }}>
              Cancel
            </MDButton>
            <MDButton variant="contained" color="success" onClick={handleSaveEditedInPatient}>
              Save
            </MDButton>
          </MDBox>
        </Card>
      </Modal>

      {/* View Modal */}
      <Modal open={openViewModal} onClose={handleCloseViewModal}>
        <Card
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            p: 4,
          }}
        >
          <MDTypography variant="h4" fontWeight="bold" mb={2}>
            Patient Details
          </MDTypography>
          {viewingInPatient && (
            <>
              <MDBox mb={1}>
                <MDTypography variant="span" color="dark">
                  <strong>UHID:</strong> {viewingInPatient.uhid}
                </MDTypography>
              </MDBox>
              <MDBox mb={1}>
                <MDTypography variant="span" color="dark">
                  <strong>Name:</strong> {viewingInPatient.name}
                </MDTypography>
              </MDBox>
              <MDBox mb={1}>
                <MDTypography variant="span" color="dark">
                  <strong>Age:</strong> {viewingInPatient.age}
                </MDTypography>
              </MDBox>
              <MDBox mb={1}>
                <MDTypography variant="span" color="dark">
                  <strong>Admission Date:</strong> {viewingInPatient.admissionDate}
                </MDTypography>
              </MDBox>
              <MDBox mb={1}>
                <MDTypography variant="span" color="dark">
                  <strong>Discharge Date:</strong> {viewingInPatient.dischargeDate}
                </MDTypography>
              </MDBox>
              <MDBox mb={1}>
                <MDTypography variant="span" color="dark">
                  <strong>Services:</strong> {viewingInPatient.services}
                </MDTypography>
              </MDBox>
              <MDBox mb={1}>
                <MDTypography variant="span" color="dark">
                  <strong>Fees:</strong> {viewingInPatient.fees}
                </MDTypography>
              </MDBox>
              <MDBox mb={1}>
                <MDTypography variant="span" color="dark">
                  <strong>Blood Pressure:</strong> {viewingInPatient.pressure}
                </MDTypography>
              </MDBox>
              <MDBox mb={1}>
                <MDTypography variant="span" color="dark">
                  <strong>SpO2:</strong> {viewingInPatient.spo2}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="flex-end">
                <MDButton variant="contained" color="success" onClick={handleEditButtonClickInViewModal} sx={{ mr: 2 }}>
                  Edit
                </MDButton>
                <MDButton variant="contained" color="error" onClick={handleCloseViewModal}>
                  Close
                </MDButton>
              </MDBox>
            </>
          )}
        </Card>
      </Modal>
    </DashboardLayout>
  );
};

export default InPatientsLists;
