import React, { useState } from "react";
import { MultiSelect } from 'primereact/multiselect';
import { useNavigate } from "react-router-dom";

import MDInput from "components/MDInput";
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MultiSelectComponent from "components/MulitiSelect";
import { initialDoctors } from "layouts/doctors/doctors";

function PatientsRegistration() {
 
  const  doctors  = initialDoctors;
  console.log(doctors.initialDoctors);

  const [patientType, setPatientType] = useState('op'); 
  const navigate  = useNavigate();

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (patientType === 'ip') {
      navigate('/inpatients');
    } else {
      navigate('/outpatients');
    }
  };

  const handlePatientTypeChange = (event) => {
    setPatientType(event.target.value);
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <form onSubmit={handleFormSubmit}>
          <div className="flex justify-center mt-8">
            <div className="w-2/4">
              <div className="flex flex-col justify-center items-center gap-4">
                <div className="w-3/4 pr-1 mb-4">
                  <MDInput id="uhid" name="uhid" label="UHID" fullWidth />
                </div>
                <div className="w-3/4 pr-1 mb-4">
                  <MDInput id="name" name="name" label="Name" fullWidth />
                </div>
                <div className="w-3/4 pr-1 mb-4">
                  <MDInput id="age" name="age" label="Age" fullWidth />
                </div>
                <div className="w-3/4 pr-1 mb-4">
                  <MDInput id="phone" name="phone" label="Phone No" fullWidth />
                </div>
                <div className="w-3/4 pr-1 mb-4">
                  <MDInput id="email" name="email" label="Email Id" fullWidth />
                </div>
              </div>
            </div>
            <div className="w-2/4">
              <div className="flex flex-col justify-center items-center gap-4">
                <div className="w-3/4 pr-1 mb-4">
                  <MDInput id="city" name="city" label="City" fullWidth />
                </div>
                <div className="w-3/4 pr-1 mb-4">
                  <MDInput id="country" name="country" label="Country" fullWidth />
                </div>
                <div className="w-3/4 pr-1 mb-4">
                  <label className="block text-sm font-medium text-gray-500 mb-2">Patient Type</label>
                  <div className="flex items-center mt-2">
                    <input
                      type="radio"
                      id="op"
                      name="patientType"
                      value="op"
                      checked={patientType === 'op'}
                      onChange={handlePatientTypeChange}
                      className="h-4 w-4 text-gray-500 focus:ring-indigo-500 cursor-pointer border-gray-300"
                    />
                    <label htmlFor="op" className="ml-2 text-sm cursor-pointer text-gray-500">
                      OP (Out Patient)
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="ip"
                      name="patientType"
                      value="ip"
                      checked={patientType === 'ip'}
                      onChange={handlePatientTypeChange}
                      className="h-4 w-4 text-gray-500 focus:ring-indigo-500 cursor-pointer border-gray-300"
                    />
                    <label htmlFor="ip" className="ml-2 text-sm cursor-pointer text-gray-500">
                      IP (In Patient)
                    </label>
                  </div>
                </div>

                <div className="w-3/4 pr-1 mb-4">
                  <label htmlFor="multiSelect" className="block text-sm font-medium text-gray-500 mb-2">
                    Select Doctor
                  </label>
                             
                    <MultiSelectComponent id="multiSelect" name="multiSelect" options={doctors} placeholder="Select Doctor" />
           
                </div>

              </div>
            </div>
          </div>

          <div className="mt-6 text-center">
            <MDButton type="submit" variant="gradient" color="info">
              Add Patient
            </MDButton>
          </div>

        </form>
      </DashboardLayout>
    </div>
  )
}

export default PatientsRegistration;
