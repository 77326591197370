import React, { useState } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import 'primereact/resources/themes/saga-blue/theme.css'; // Replace with your desired PrimeReact theme
import 'primereact/resources/primereact.min.css'; // PrimeReact CSS


const MultiSelectComponent = ({ options, placeholder }) => {
  const [selectedValues, setSelectedValues] = useState([]);

  // Custom panel style for the dropdown options
  const customPanelStyle = {
    backgroundColor: '#f0f4f7', 
    border: '1px solid #dce4ec', 
    borderRadius: '4px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    padding: '8px', 
  };

  // Custom style for option item 
  const customOptionStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '8px', 
  };

  return (
    <div className="flex justify-center ">
      <div className="w-full">
        
        <MultiSelect
          id="multiSelect"
          name="multiSelect"
          value={selectedValues}
          onChange={(e) => setSelectedValues(e.value)}
          options={options}
          optionLabel="label"
          placeholder={placeholder}
          className="w-full border-gray-200 bg-gray-100 border-2 rounded-lg p-3 text-sm"
          panelStyle={customPanelStyle} // Apply custom panel style
          appendTo={document.body} 
          itemTemplate={(option) => (
            <div style={customOptionStyle}>
              <input
                type="checkbox"
                checked={selectedValues.includes(option.value)}
                onChange={() => {}}
                style={{ marginRight: '10px', display:'none'}} 
              />
              <span>{option.label}</span>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default MultiSelectComponent;
