import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import Modal from '@mui/material/Modal';

const OutPatientsLists = () => {
  const initialOutPatients = [
    {
      id: 1,
      name: 'John Doe',
      age: 30,
      phoneNumber: '123-456-7890',
      city: 'New York',
      fees: 100,
      email: 'john.doe@example.com',
      country: 'USA',
      uhid: 'UHID123',
      doctorName: 'Dr. Smith',
    },
    {
      id: 2,
      name: 'Jane Smith',
      age: 25,
      phoneNumber: '234-567-8901',
      city: 'Los Angeles',
      fees: 150,
      email: 'jane.smith@example.com',
      country: 'USA',
      uhid: 'UHID124',
      doctorName: 'Dr. Johnson',
    },
    {
      id: 3,
      name: 'Sam Wilson',
      age: 40,
      phoneNumber: '345-678-9012',
      city: 'Chicago',
      fees: 200,
      email: 'sam.wilson@example.com',
      country: 'USA',
      uhid: 'UHID125',
      doctorName: 'Dr. Brown',
    },
    {
      id: 4,
      name: 'Lucy Brown',
      age: 35,
      phoneNumber: '456-789-0123',
      city: 'Houston',
      fees: 120,
      email: 'lucy.brown@example.com',
      country: 'USA',
      uhid: 'UHID126',
      doctorName: 'Dr. White',
    },
    {
      id: 5,
      name: 'Michael Johnson',
      age: 28,
      phoneNumber: '567-890-1234',
      city: 'Phoenix',
      fees: 130,
      email: 'michael.johnson@example.com',
      country: 'USA',
      uhid: 'UHID127',
      doctorName: 'Dr. Green',
    },
    {
      id: 6,
      name: 'Emily Davis',
      age: 32,
      phoneNumber: '678-901-2345',
      city: 'Philadelphia',
      fees: 110,
      email: 'emily.davis@example.com',
      country: 'USA',
      uhid: 'UHID128',
      doctorName: 'Dr. Blue',
    },
    {
      id: 7,
      name: 'David Martinez',
      age: 45,
      phoneNumber: '789-012-3456',
      city: 'San Antonio',
      fees: 140,
      email: 'david.martinez@example.com',
      country: 'USA',
      uhid: 'UHID129',
      doctorName: 'Dr. Black',
    },
    {
      id: 8,
      name: 'Laura Garcia',
      age: 50,
      phoneNumber: '890-123-4567',
      city: 'San Diego',
      fees: 160,
      email: 'laura.garcia@example.com',
      country: 'USA',
      uhid: 'UHID130',
      doctorName: 'Dr. Red',
    },
    {
      id: 9,
      name: 'Daniel Hernandez',
      age: 37,
      phoneNumber: '901-234-5678',
      city: 'Dallas',
      fees: 170,
      email: 'daniel.hernandez@example.com',
      country: 'USA',
      uhid: 'UHID131',
      doctorName: 'Dr. Gray',
    },
    {
      id: 10,
      name: 'Sophie Lopez',
      age: 29,
      phoneNumber: '012-345-6789',
      city: 'San Jose',
      fees: 180,
      email: 'sophie.lopez@example.com',
      country: 'USA',
      uhid: 'UHID132',
      doctorName: 'Dr. Yellow',
    },
  ];


  const [outPatients, setOutPatients] = useState(initialOutPatients);
  const [editingOutPatient, setEditingOutPatient] = useState(null);
  const [viewingOutPatient, setViewingOutPatient] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handleEditOutPatient = (outPatient) => {
    setEditingOutPatient(outPatient);
    setOpenEditModal(true);
  };

  const handleSaveEditedOutPatient = () => {
    setOutPatients((prevOutPatients) =>
      prevOutPatients.map((patient) =>
        patient.id === editingOutPatient.id ? editingOutPatient : patient
      )
    );
    setEditingOutPatient(null);
    setOpenEditModal(false);
  };

  const handleCloseEditModal = () => {
    setEditingOutPatient(null);
    setOpenEditModal(false);
  };

  const handleViewOutPatient = (outPatient) => {
    setViewingOutPatient(outPatient);
    setOpenViewModal(true);
  };

  const handleCloseViewModal = () => {
    setViewingOutPatient(null);
    setOpenViewModal(false);
  };

  const handleEditButtonClickInViewModal = () => {
    if (viewingOutPatient) {
      handleEditOutPatient(viewingOutPatient);
      setOpenViewModal(false);
    }
  };

  const filteredOutPatients = outPatients.filter((outPatient) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      outPatient.uhid.toLowerCase().includes(searchLower) ||
      outPatient.name.toLowerCase().includes(searchLower) ||
      outPatient.phoneNumber.toLowerCase().includes(searchLower) ||
      outPatient.city.toLowerCase().includes(searchLower) ||
      outPatient.age.toString().includes(searchLower)
    );
  });

  const columns = [
    { Header: '#', accessor: 'serialNo', width: '5%', align: 'center' },
    { Header: 'UHID', accessor: 'uhid', width: '15%', align: 'center' },
    { Header: 'Name', accessor: 'name', width: '20%', align: 'left' },
    { Header: 'Age', accessor: 'age', width: '10%', align: 'center' },
    { Header: 'Phone Number', accessor: 'phoneNumber', width: '15%', align: 'left' },
    { Header: 'City', accessor: 'city', width: '15%', align: 'center' },
    { Header: 'Fees', accessor: 'fees', width: '15%', align: 'center' },
    { Header: 'Action', accessor: 'action', align: 'center' },
  ];

  const rows = filteredOutPatients.map((outPatient, index) => ({
    serialNo: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {index + 1}
      </MDTypography>
    ),
    uhid: (
      <MDTypography
        component="button"
        variant="caption"
        color="text"
        fontWeight="medium"
        onClick={() => handleViewOutPatient(outPatient)}
      >
        {outPatient.uhid}
      </MDTypography>
    ),
    name: (
      <MDTypography
        component="button"
        variant="caption"
        color="text"
        fontWeight="medium"
        onClick={() => handleViewOutPatient(outPatient)}
      >
        {outPatient.name}
      </MDTypography>
    ),
    age: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {outPatient.age}
      </MDTypography>
    ),
    phoneNumber: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {outPatient.phoneNumber}
      </MDTypography>
    ),
    city: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {outPatient.city}
      </MDTypography>
    ),
    fees: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {outPatient.fees}
      </MDTypography>
    ),
    action: (
      <MDButton variant="text" color="info" size="small" onClick={() => handleEditOutPatient(outPatient)}>
        Edit
      </MDButton>
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={4}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={3}
                mt={-3}
                py={2}
                px={2}
                variant="gradient"
                bgColor="customGradient"
                borderRadius="lg"
                coloredShadow="#EA257C"
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <MDTypography variant="h6" color="white">
                  Out Patients
                </MDTypography>
                <MDBox display="flex" gap={2}>
                  <MDInput
                    label="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <Link to="/patientsregistration">
                    <MDButton variant="contained" color="white" >
                      Add OP
                    </MDButton>
                  </Link>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* Edit Modal */}
      <Modal open={openEditModal} onClose={handleCloseEditModal}>
        <MDBox
          p={3}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: 'white',
            boxShadow: 24,
            borderRadius: '8px',
          }}
        >
          <MDTypography variant="h5" gutterBottom fontWeight="bold">
            Edit Out Patient
          </MDTypography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDInput
                label="UHID"
                value={editingOutPatient?.uhid || ''}
                onChange={(e) => setEditingOutPatient({ ...editingOutPatient, uhid: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MDInput
                label="Name"
                value={editingOutPatient?.name || ''}
                onChange={(e) => setEditingOutPatient({ ...editingOutPatient, name: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MDInput
                label="Age"
                type="number"
                value={editingOutPatient?.age || ''}
                onChange={(e) => setEditingOutPatient({ ...editingOutPatient, age: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MDInput
                label="Phone Number"
                value={editingOutPatient?.phoneNumber || ''}
                onChange={(e) => setEditingOutPatient({ ...editingOutPatient, phoneNumber: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MDInput
                label="City"
                value={editingOutPatient?.city || ''}
                onChange={(e) => setEditingOutPatient({ ...editingOutPatient, city: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MDInput
                label="Fees"
                type="number"
                value={editingOutPatient?.fees || ''}
                onChange={(e) => setEditingOutPatient({ ...editingOutPatient, fees: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MDInput
                label="Email"
                value={editingOutPatient?.email || ''}
                onChange={(e) => setEditingOutPatient({ ...editingOutPatient, email: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MDInput
                label="Country"
                value={editingOutPatient?.country || ''}
                onChange={(e) => setEditingOutPatient({ ...editingOutPatient, country: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MDInput
                label="Doctor Name"
                value={editingOutPatient?.doctorName || ''}
                onChange={(e) => setEditingOutPatient({ ...editingOutPatient, doctorName: e.target.value })}
                fullWidth
              />
            </Grid>
          </Grid>
          <MDBox display="flex" justifyContent="flex-end" mt={2}>
            <MDButton variant="contained" color="error" onClick={handleCloseEditModal} sx={{ mr: 2 }}>
              Cancel
            </MDButton>
            <MDButton variant="contained" color="success" onClick={handleSaveEditedOutPatient}>
              Save
            </MDButton>
          </MDBox>
        </MDBox>
      </Modal>

      {/* View Modal */}
      <Modal open={openViewModal} onClose={handleCloseViewModal}>
        <Card
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            p: 4,
          }}
        >
          <MDTypography variant="h4" mb={2} gutterBottom fontWeight="bold" >
            {viewingOutPatient?.name} Details
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="span" color="dark">
              <strong>UHID:</strong> {viewingOutPatient?.uhid}
            </MDTypography>
          </MDBox>
          <MDBox mb={1}>
            <MDTypography variant="span" color="dark">
              <strong>Name:</strong> {viewingOutPatient?.name}
            </MDTypography>
          </MDBox>
          <MDBox mb={1}>
            <MDTypography variant="span" color="dark">
              <strong>Age:</strong> {viewingOutPatient?.age}
            </MDTypography>
          </MDBox>
          <MDBox mb={1}>
            <MDTypography variant="span" color="dark">
              <strong>Phone Number:</strong> {viewingOutPatient?.phoneNumber}
            </MDTypography>
          </MDBox>
          <MDBox mb={1}>
            <MDTypography variant="span" color="dark">
              <strong>City:</strong> {viewingOutPatient?.city}
            </MDTypography>
          </MDBox>
          <MDBox mb={1}>
            <MDTypography variant="span" color="dark">
              <strong>Fees:</strong> {viewingOutPatient?.fees}
            </MDTypography>
          </MDBox>
          <MDBox mb={1}>
            <MDTypography variant="span" color="dark">
              <strong>Email:</strong> {viewingOutPatient?.email}
            </MDTypography>
          </MDBox>
          <MDBox mb={1}>
            <MDTypography variant="span" color="dark">
              <strong>Country:</strong> {viewingOutPatient?.country}
            </MDTypography>
          </MDBox>
          <MDBox mb={1}>
            <MDTypography variant="span" color="dark">
              <strong>Doctor Name:</strong> {viewingOutPatient?.doctorName}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" justifyContent="flex-end" mt={2}>
            <MDButton variant="contained" color="success" onClick={handleEditButtonClickInViewModal} sx={{ mr: 2 }}>
              Edit
            </MDButton>
            <MDButton variant="contained" color="error" onClick={handleCloseViewModal}>
              Close
            </MDButton>
          </MDBox>
        </Card>
      </Modal>
    </DashboardLayout>
  );
};

export default OutPatientsLists;
