// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import InPatientsLists from "layouts/Patients/InPatientLists";
import OutPatientsLists from "layouts/Patients/OutPatientLists";
import PatientsRegistration from "layouts/Patients/PatientsRegistration";
import Services from "layouts/Services/Services";
import OpData from "layouts/Patients/data/OutPatientsdata";
import Doctors from "layouts/doctors/doctors";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: <Tables />,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "RTL",
    key: "rtl",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    route: "/rtl",
    component: <RTL />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
 
  {
    type: "collapse",
    name: "Out Patients",
    key: "outpatients",
    route: "/outpatients",
    component: <OutPatientsLists />,
  },
  {
    type: "collapse",
    name: "In Patients",
    key: "inpatients",
    route: "/inpatients",
    component: <InPatientsLists />,
  },
  {
    type: "collapse",
    name: "Patients Registration",
    key: "patientsregistration",
    route: "/patientsregistration",
    component: <PatientsRegistration />,
  },
  {
    type: "collapse",
    name: "Doctors",
    key: "doctors",
    route: "/doctors",
    component: <Doctors />,
  },
  {
    type: "collapse",
    name: "Services",
    key: "services",
    route: "/services",
    component: <Services />,
  },
];

export default routes;
