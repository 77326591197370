/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import GroupIcon from '@mui/icons-material/Group';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import ScheduleIcon from '@mui/icons-material/Schedule';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={8} px={8}>
  <Grid container spacing={10}>
    <Grid item xs={12} sm={6}>
      <MDBox mb={1.5}>
        <ComplexStatisticsCard
          color="dark"
          icon="group"
          title="Total Patients"
          count={281}
          percentage={{
            color: "success",
            amount: "",
            label: "",
          }}
        />
      </MDBox>
    </Grid>
    <Grid item xs={12} sm={6}>
      <MDBox mb={1.5}>
        <ComplexStatisticsCard
          icon= "local_hospital_icon"
          title="In Patients"
          count="2,300"
          percentage={{
            color: "success",
            amount: "",
            label: "",
          }}
        />
      </MDBox>
    </Grid>
    <Grid item xs={12} sm={6}>
      <MDBox mb={1.5}>
        <ComplexStatisticsCard
          color="success"
          icon= "medical_services_icon"
          title="Out Patients"
          count="34k"
          percentage={{
            color: "success",
            amount: "",
            label: "",
          }}
        />
      </MDBox>
    </Grid>
    <Grid item xs={12} sm={6}>
      <MDBox mb={1.5}>
        <ComplexStatisticsCard
          color="primary"
          icon="schedule_icon"
          title="Today Patients"
          count="+91"
          percentage={{
            color1: "success",
            amount1: "21",
            label1: "In Patients",
            color2: "success",
            amount2: "10",
            label2: "Out Patients",
          }}
        />
      </MDBox>
    </Grid>
  </Grid>
        {/* <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox> */}
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Dashboard;
