import React, { useState } from 'react';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import Modal from '@mui/material/Modal'; 

export const InitialServices = [
    { id: 1, name: 'Blood Test', amount: 100 },
    { id: 2, name: 'Scan', amount: 150 },
    { id: 4, name: 'MRI', amount: 250 },
    { id: 5, name: 'Ultrasound', amount: 180 },
    { id: 6, name: 'Echocardiogram', amount: 200 },
    { id: 7, name: 'Biopsy', amount: 300 },
    { id: 8, name: 'Colonoscopy', amount: 350 },
    { id: 9, name: 'Endoscopy', amount: 280 },
    { id: 10, name: 'Physical Therapy', amount: 80 },
    { id: 11, name: 'Occupational Therapy', amount: 90 },
    { id: 12, name: 'Speech Therapy', amount: 70 },
  ];

const Services = () => {
  const [services, setServices] = useState(InitialServices);
  const [serviceName, setServiceName] = useState('');
  const [amount, setAmount] = useState('');
  const [nextId, setNextId] = useState(1); // Counter for generating IDs

  // State for editing modal
  const [editingServiceId, setEditingServiceId] = useState(null);
  const [editedServiceName, setEditedServiceName] = useState('');
  const [editedServiceAmount, setEditedServiceAmount] = useState('');

  // Modal state
  const [openModal, setOpenModal] = useState(false);

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (serviceName.trim() === '' || amount.trim() === '') return;

    // Create new service object
    const newService = {
      id: nextId,
      name: serviceName,
      amount: parseFloat(amount),
    };

    // Add new service to the services list
    setServices([...services, newService]);

    // Increment nextId for the next service
    setNextId(nextId + 1);

    // Clear inputs
    setServiceName('');
    setAmount('');
  };

  // Handle input changes
  const handleServiceNameChange = (e) => {
    setServiceName(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  // Open modal for editing service
  const handleEditService = (serviceId) => {
    const serviceToEdit = services.find((service) => service.id === serviceId);
    if (serviceToEdit) {
      setEditingServiceId(serviceId);
      setEditedServiceName(serviceToEdit.name);
      setEditedServiceAmount(serviceToEdit.amount);
      setOpenModal(true);
    }
  };

  // Handle saving edited service
  const handleSaveEditedService = () => {
    const updatedServices = services.map((service) =>
      service.id === editingServiceId
        ? {
            ...service,
            name: editedServiceName,
            amount: parseFloat(editedServiceAmount),
          }
        : service
    );
    setServices(updatedServices);
    setEditingServiceId(null);
    setEditedServiceName('');
    setEditedServiceAmount('');
    setOpenModal(false);
  };

  // Close modal
  const handleCloseModal = () => {
    setEditingServiceId(null);
    setEditedServiceName('');
    setEditedServiceAmount('');
    setOpenModal(false);
  };

  const columns = [
    { Header: '#', accessor: 'serialNo', width: '5%', align: 'center' },
    { Header: 'Name', accessor: 'name', width: '20%', align: 'left' },
    { Header: 'Amount', accessor: 'amount', width: '10%', align: 'center' },
    { Header: 'Action', accessor: 'action', align: 'center' },
  ];

  // Prepare rows for DataTable
  const rows = services.map((service, index) => ({
    serialNo: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {index + 1}
      </MDTypography>
    ),
    name: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {service.name}
      </MDTypography>
    ),
    amount: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {service.amount}
      </MDTypography>
    ),
    action: (
      <MDTypography
        component="button"
        variant="caption"
        color="text"
        fontWeight="medium"
        onClick={() => handleEditService(service.id)}
      >
        Edit
      </MDTypography>
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="flex justify-center mt-8 mx-5">
        <div className="w-1/2 flex h-80 justify-center">
          <div className="w-4/5 p-10 mx-10 my-5 bg-white rounded-lg shadow-md">
            <h2 className="text-lg font-medium text-gray-800 mb-4">Add Services</h2>

            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <MDInput label="Service Name" value={serviceName} onChange={handleServiceNameChange} fullWidth />
              </div>

              <div className="mb-4">
                <MDInput
                  label="Amount"
                  type="number"
                  value={amount}
                  onChange={handleAmountChange}
                  fullWidth
                />
              </div>
              <div className="mt-6 text-center">
                <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                  Add Services
                </MDButton>
              </div>
            </form>
          </div>
        </div>
        <div className="w-1/2">
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="customGradient"
                    borderRadius="lg"
                    coloredShadow="#EA257C"
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  >
                    <MDTypography variant="h6" color="white">
                      Available Services
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </div>
      </div>

      {/* Editing Modal */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="edit-service-modal"
        aria-describedby="modal to edit service"
      >
        <div className="modal-container flex justify-center mt-40  ">
          <Card className="modal-card w-2/5">
            <MDBox p={3}>
              <h2 className="text-lg font-medium text-gray-800 mb-5 mx-5">Edit Service</h2>
              <div className="mb-5 mx-5">
                <MDInput
                  label="Service Name"
                  value={editedServiceName}
                  onChange={(e) => setEditedServiceName(e.target.value)}
                  fullWidth
                />
              </div>

              <div className="mb-5 mx-5">
                <MDInput
                  label="Amount"
                  type="number"
                  value={editedServiceAmount}
                  onChange={(e) => setEditedServiceAmount(e.target.value)}
                  fullWidth
                />
              </div>

              <div className="flex justify-end gap-5 mx-5">
                <MDButton variant="outlined" color="primary" onClick={handleCloseModal} >
                  Cancel
                </MDButton>
                <MDButton variant="gradient" color="info" onClick={handleSaveEditedService}>
                  Save
                </MDButton>
              </div>
            </MDBox>
          </Card>
        </div>
      </Modal>
    </DashboardLayout>
  );
};

export default Services;
